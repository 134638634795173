import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
// import ThemeToggle from "./ThemeToggle"
import Footer from "./Footer"
import "./layout.css"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          id="site-title"
          css={{
            fontFamily: "inherit",
            display: "flex",
            alignItems: "center",
            ...scale(0.75),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            css={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          id="site-title"
          className="not-homepage"
          css={{
            fontFamily: "inherit",
            marginTop: 10,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div
        className="layout"
        style={{
          color: "var(--text-color)",
          marginLeft: `auto`,
          marginRight: `auto`,
          background: "var(--layout-bg)",
          border: "1px solid rgba(0,0,0, 0.3)",
          transition: "color 0.2s ease-out, background 0.2s ease-out",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            maxWidth: rhythm(24),
            margin: "0 auto",
            padding: `2.625rem ${rhythm(3 / 4)}`,
          }}
        >
          <header
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontFamily: `Montserrat, sans-serif`,
            }}
          >
            {header}
            <img src={"/gopher-dance.gif"} alt="Logo" />
          </header>
          <main>{children}</main>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Layout
