import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

const ExternalLink = ({ children, ...props }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  )
}

const separator = <> &bull; </>
class Footer extends React.Component {
  render() {
    return (
      <StaticQuery
        query={footerQuery}
        render={data => {
          const { social } = data.site.siteMetadata

          return (
            <footer
              style={{
                marginTop: rhythm(2.5),
                paddingTop: rhythm(1),
              }}
            >
              <ExternalLink href={social.twitter.url}>twitter</ExternalLink>
              {separator}
              <ExternalLink href={social.github.url}>github</ExternalLink>
              {separator}
              <ExternalLink href={social.linkedin.url}>linkedIn</ExternalLink>
              {separator}
              <ExternalLink href="https://jagasantagostino.com">
                hire me
              </ExternalLink>
            </footer>
          )
        }}
      />
    )
  }
}

const footerQuery = graphql`
  query FooterQuery {
    site {
      siteMetadata {
        author
        social {
          twitter {
            url
          }
          github {
            url
          }
          linkedin {
            url
          }
        }
      }
    }
  }
`

export default Footer
