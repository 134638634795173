import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            css={{
              display: `flex`,
              alignItems: "center",
              marginBottom: rhythm(2),
              "@media (max-width: 768px)": {
                flexDirection: "column",
              },
            }}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              css={{
                display: "block",
                marginRight: "1.2rem",
                borderRadius: `8px`,
                "@media (max-width: 768px)": {
                  marginBottom: "1.2rem",
                  marginRight: 0,
                },
              }}
            />
            <p style={{ marginBottom: 0 }}>
              Blog by{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={social.twitter.url}
              >
                {author}
              </a>
              .
              <br />
              Software consultant, polyglot developer, maker of things, lifelong
              learner.
              <br />
              <a href="https://www.meetup.com/it-IT/React-JS-Milano/">
                ReactJS Milano
              </a>{" "}
              & <a href="https://milano.dev">milano.dev</a> organizer
            </p>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter {
            url
          }
        }
      }
    }
  }
`

export default Bio
